<template></template>

<script>
	export default {
		mounted () {
			// Redirect to 1st module
			if (this.$store.state.modules.length > 0){
				this.$router.push({ path: this.$store.state.modules[0].to })
			} else {
				this.$router.push({ path: '/newmodule' })
			}
		},
	}
</script>