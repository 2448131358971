<template>
	<!-- <v-container fluid mt-5 mb-5 pa-0> -->
	<v-container fluid mt-5 mb-5 pa-0 >
		<v-row no-gutters>
			<v-col class="pa-10">
				<v-simple-table>
					<template v-slot:default>
					<thead>
						<tr>
						<th class="text-center">
							Źródło 
						</th>
						<th class="text-center">
							Status
						</th>
						<th class="text-center">
							Pobrane
						</th>
						</tr>
					</thead>
					<tbody>
						<tr
						v-for="item in datasources"
						:key="item.name"
						>
						<td class="text-center">{{ item.Name }}</td>
						<td class="text-center"> {{ item.Status }}</td>
						<td class="text-center">{{ item.Lastload }}</td>

						</tr>
					</tbody>
					</template>
				</v-simple-table>
			</v-col>
			<v-col class="pa-10">
				<v-simple-table>
					<template v-slot:default>
					<thead>
						<tr>
						<th class="text-center">
							Integracja
						</th>
						<th class="text-center">
							Status
						</th>
						</tr>
					</thead>
					<tbody>
						<tr
						v-for="item in integrations"
						:key="item.name"
						>
						<td class="text-center">{{ item.Name }}</td>
						<td class="text-center">{{ item.Status }}</td>
						</tr>
					</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
	</v-container>

</template>

<script>
export default {
	methods: {
		loadData(){
				this.loading = true
				axios.get(process.env.VUE_APP_API_URL + '/stock/'+this.$route.params.module_id)
					.then(response => { 
						this.integrations = response.data.Integrations
						this.datasources = response.data.Datasources
						console.log(response.data)
						this.loading = false
					 })
					.catch(	error => { 
						this.$store.commit('openDialog', {heading: 'Błąd ', message: 'Ładowanie danych nieudane.'})
						this.loading = false
					});

			},
	},

	mounted () {

		this.loadData()
	},

	data: () => ({ 
		integrations : [],
		datasources : [],

	})
}
</script>