<template>
	<div class="text-center">
	<v-dialog
	  v-model="dialog"
	  persistent
	  width="500"
	>
	<!-- input form -->

		<v-card>
			<v-card-title class="headline grey lighten-2 text-body-1">
				{{title}}
			</v-card-title>
			<v-card-text class="mt-5">
			<v-textarea
				v-model="note"
				:counter="255"
				outlined
				name="input-7-4"
				label="Notatki Oferty"
				:value="note"
			></v-textarea>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
			<v-spacer></v-spacer>
			<!-- on save emit save -->
			<v-btn
				text
				@click="$emit('closeNote' )"
			>
				OK
			</v-btn>
				<v-btn
					text
					@click="$emit('saveNote' , note)"
				>
				ZAPISZ
			</v-btn>
		</v-card-actions>
	  </v-card>
	</v-dialog>
	</div>
</template>

<script>
	export default {
		props : [
			'dialog',
			'title',
			'note',
		],

		data: () => ({ 
			note_text : '',
		}),
	}
</script>
 