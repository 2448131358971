<template>
	<!-- <v-container fluid mt-5 mb-5 pa-0> -->
	<v-container fluid mt-5 mb-5 pa-0 >

		<!-- module disabled notification --->
		<v-container fluid ma-0 pa-0 v-if="moduleDisabled">
			<v-alert text prominent type="error"  class="rounded-0">
				Usługa nie jest aktywna - skontaktuj się aby włączyć:  

				<v-btn small  depressed href="mailto:botcenowy@gmail.com?subject=BotCen Aktywacja Usługi" target="_blank"  class="float-right" color="primary">
					Pomoc
				</v-btn> 
			</v-alert>
		</v-container>

		<notifications :notifications=notifications  :module_id=this.$route.params.module_id module_type="ceneo"/>

		<v-toolbar
			flat
			>
			<v-text-field
				v-model="search_string"
				v-on:change="searchTable"
				append-icon="mdi-magnify"
				label="Wyszukiwanie"
				hide-details
				clearable
				outlined
			></v-text-field>

			<v-spacer></v-spacer>

			<v-btn 	 
				plain 	
				x-small	
				class="mr-5"
				@click="filter_favorites = !filter_favorites" 
				:class="{'blue-grey lighten-4' : filter_favorites	}"
				>
				<v-icon left>mdi-star</v-icon>
				Wyróżnione
			</v-btn>

			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn 	 
						v-on="on"
						plain 	
						x-small	
						class="mr-1" 
						@click="filter_active = !filter_active" 
						:class="{'blue-grey lighten-4' : filter_active	}"
					>
						<v-icon left> mdi-play-circle-outline </v-icon>
						Aktywne
					</v-btn>
				 </template>
			    <span> Wyświetl tylko monitorowane oferty </span>
    		</v-tooltip>	


			<!-- <v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn 	
						v-on="on"
						plain 	
						x-small	
						class="mr-1"
						@click="filter_high = !filter_high" 
						:class="{'blue-grey lighten-4' : filter_high	}"
					>
						<v-icon left> mdi-arrow-up-bold-outline </v-icon>
						Wysoka

					</v-btn>
				 </template>
			    <span>Zbyt wysoka cena oferty</span>
    		</v-tooltip>	

			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn 	
						v-on="on"
			
						plain 	
						x-small	
						class="mr-1" 
						@click="filter_low = !filter_low" 
						:class="{'blue-grey lighten-4' : filter_low}">
						<v-icon left> mdi-arrow-down-bold-outline </v-icon>
						Niska
					</v-btn>
				</template>
			    <span>Zbyt niska cena oferty</span>
    		</v-tooltip> -->



			<v-btn 	 
				plain 	 
				x-small	
				class="mr-4"
				:href="api_url + '/ceneo/'+this.$route.params.module_id+'/export'"
			>
				
				Export CSV				
			</v-btn>
			
			<v-btn  plain 	x-small v-on:click="loadData" class="mr-5">
				<v-icon left>mdi-refresh</v-icon>
				Ośwież				
			</v-btn>


			
			<!-- <v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn 	
						v-on="on"
						plain 	
						x-small	
						class="mr-1" 
						@click="importOffers" >
						<v-icon left> mdi-arrow-right-bold-outline </v-icon>
						Import
					</v-btn>
				</template>
			    <span>Import ofert z ceneo</span>
    		</v-tooltip>	 -->

		</v-toolbar>

		<v-container fluid pa-2>
			<products-table  
				:products=products 
				:search=search
				:filter_active=filter_active
				:filter_high=filter_high
				:filter_low=filter_low
				:filter_favorites=filter_favorites
				:module_id=module_id
				:loading=loading
			/>
		</v-container>

		
	</v-container>
</template>

<script>

	import ProductsTable from '../components/ceneo/ProductsTable.vue'
	import Nofifications from '../components/Notifications.vue'
	import Tutorial from '../components/Tutorial.vue'
	
	export default {
		
		components :{
			'products-table': ProductsTable,
			'notifications': Nofifications,
			'tutorial' : Tutorial,
		},
		methods : {

			loadData: function() {
				this.products = []
				this.notifications = []
				this.module_id = this.$route.params.module_id
				this.loadOffers()
			},
				
			searchTable () {
				// trigger search after typing change
				this.search = this.search_string;
			},

			loadOffers(){
				this.loading = true
				axios.get(process.env.VUE_APP_API_URL + '/ceneo/'+this.$route.params.module_id)
					.then(response => { 
						this.products = response.data
						this.loadNotif()  // load notifications after swap
						this.loading = false
					 })
					.catch(	error => { 
						this.$store.commit('openDialog', {heading: 'Błąd ', message: 'Ładowanie danych nieudane.'})
						this.loading = false
					});

			},

			loadNotif () {
				axios.get(process.env.VUE_APP_API_URL + '/ceneo/'+this.$route.params.module_id+'/notifs')
					.then(response => { 
						this.notifications = response.data

						
					 })
					.catch(	error => { 
						this.$store.commit('openDialog', {heading: 'Błąd ', message: 'Ładowanie danych nieudane.'})
					});
				
			},

			importOffers ( ) {
				axios.get(process.env.VUE_APP_API_URL + '/ceneo/'+this.$route.params.module_id+'/import')
					.then(response => { 
						// this.loadNotif()
						this.$store.commit('openDialog', {
							heading: 'Importowanie Ofert Rozpoczęte', 
							message: 'Lista ofert zostanie automatycznie zaktualizowana. Świeżo dodane oferty wymagają konfiguracji i włączenia skanowania. System tylko wykonuje jeden import dziennie.'
							})
					 })
					.catch(	error => { 
						this.$store.commit('openDialog', {heading: 'Błąd ', message: 'Ładowanie danych nieudane.'})
				});

			},

			getModuleStatus(){
				for (var i = 0; i < this.$store.state.modules.length; i++) {
					if (this.$store.state.modules[i].module_id == this.$route.params.module_id ){
							return this.$store.state.modules[i].status
					}
				}

				return ''
			},

			toggleTutorial () {
				this.tutorial = !this.tutorial
			},


		},

		mounted () {
			this.module_id = this.$route.params.module_id

			// check if registration is completed otherwise redirect to module creation page
			if (this.getModuleStatus() == 'payment' || this.getModuleStatus() == 'registering' ){
				this.$router.push({ path: '/newmodule/' + this.$route.params.module_id  })
			}

			this.loadData()
		},
		
		watch: { $route(to, from) { 
			// react to route changes... 
			if(to !== from){ 
				this.loadData()
			} } 
		},

		computed: {
			oauthRequired(){
				if (this.getModuleStatus() == 'oauth' ){
					return true
				}

				return false
			},

			moduleDisabled(){
				if (this.getModuleStatus() == 'disabled' ){
					return true
				}

				return false
			}

		},

		data: () => ({ 
				api_url : process.env.VUE_APP_API_URL,
				module_id : '',
				refresh_link : "/ceneo",
				search_string : '',
				search: '',
				products: [],
				filter_active : false,
				filter_high : false,
				filter_low : false ,
				filter_favorites : false,
				notifications : [],
				loading : false,
				tutorial : false,
			
		}),
	}

</script>


