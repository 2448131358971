var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"fixed-header":"","dense":"","item-key":"ID","sort-by":_vm.sort_by,"sort-desc":_vm.sort_desc,"headers":_vm.headers,"items":_vm.prices,"items-per-page":100,"item-class":_vm.rowClasses,"search":_vm.search,"hide-default-footer":!_vm.show_pagination},on:{"update:sortBy":function($event){_vm.sort_by=$event},"update:sort-by":function($event){_vm.sort_by=$event},"update:sortDesc":function($event){_vm.sort_desc=$event},"update:sort-desc":function($event){_vm.sort_desc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.offer_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.getBLOfferColor(item),"small":"","icon":""},on:{"click":function($event){return _vm.toggleBLOffer(item)}}},on),[_c('v-icon',[_vm._v("mdi-playlist-remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Dodaj ofertę do czarnej listy, oferta nie będzie używana przy kalkulacji optymalnej ceny.")])]),_c('a',{attrs:{"href":'https://allegro.pl/oferta/' + item.offer_id,"target":"_blank"}},[_vm._v(_vm._s(item.offer_id))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"small-text"},[_vm._v(_vm._s(item.name))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.price).toFixed(2))+" ")]}},{key:"item.seller_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.getBLSellerColor(item),"small":"","icon":""},on:{"click":function($event){return _vm.toggleBLSeller(item)}}},on),[_c('v-icon',[_vm._v("mdi-playlist-remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Dodaj sprzedawcę do czarnej listy, wszystkie oferty sprzedawcy będą ignorowane.")])]),_vm._v(" "+_vm._s(item.seller_name)+" "),_c('span',{staticClass:"small-text"},[_vm._v("("+_vm._s(item.seller_id)+")")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }