<template>
<div>
	<v-data-table
			fixed-header
			dense
			item-key="ID"
			:sort-by.sync="sort_by"
			:sort-desc.sync="sort_desc"
			:headers="headers"
			:items="products"
			:items-per-page="25"
			:search="search"
			:loading=loading
			loading-text="Loading... Please wait"
			:hide-default-footer=!show_pagination
			@pagination="paginationVisibility"
		>

		<template v-slot:top="{ pagination, options, updateOptions }" v-if=show_pagination>
			<v-data-footer 
				:pagination="pagination" 
				:options="options"
				@update:options="updateOptions"
				items-per-page-text="$vuetify.dataTable.itemsPerPageText"
			/>
		</template>

		
		<template v-slot:item.Ceneo_id="{ item }">
			
			<a :href="'https://www.ceneo.pl/'+item.Ceneo_id" target="_blank">{{item.Ceneo_id}}</a>
		</template>

		<template v-slot:item.Shop_id="{ item }">
			<input
				v-tooltip="{
					content:'Ustaw id oferty na systemie sklepowym - niezbędne do automatycznej zmiany ceny',
					delay: 600,
				}"
				type="text" 
				:value=item.Shop_id
				class="cust-table-input" 
				v-on:change="event => changeValue(event.target.value , item,'Shop_id')"
			>		         
		</template>

		<template v-slot:item.Name="{ item }" >
			<td style="max-width: 250px!important;">
			<!-- <td> -->
				<span class="small-text">{{item.Name}}</span>
			</td>
		</template>



		<template v-slot:item.PriceFrom="{ item }">
			<input
				v-tooltip="{
					content:'Ustaw minimalną cenę twojej oferty',
					delay: 600,
				}"
				type="text" 
				:value=priceEmpty(item.PriceFrom)
				class="cust-table-input" 
				v-on:change="event => changeValue(event.target.value , item,'PriceFrom')"
			>		         
		</template>


		<template v-slot:item.PriceTo="{ item }">
			<input
				v-tooltip="{
					content:'Ustaw maksymalną cenę twojej oferty ',
					delay: 600,
				}"
				type="text" 
				:value=priceEmpty(item.PriceTo) 
				class="cust-table-input" 
				v-on:change="event => changeValue(event.target.value , item,'PriceTo')"
			>			         
		</template>


		<template v-slot:item.PriceOptimal="{ item }">
			<span
			 v-tooltip="{
					content:'Optymalna cena oferty wyliczona przez system',
					delay: 600,
			}"
			 >
				{{ priceEmpty(item.PriceOptimal) }}
			</span>			
		</template>


		<template v-slot:item.PriceLow="{ item }">
			<span 
			v-on="on"
			v-tooltip="{
				content:'Najniższa cena konkurencji',
				delay: 600,
			}"
			>
				{{ priceEmpty(item.PriceLow) }}
			</span>		
		</template>


		<template v-slot:item.PriceLowKt="{ item }">
			<span 
			v-on="on"
			v-tooltip="{
				content:'Najniższa cena konkurencji Kup Teraz',
				delay: 600,
			}"
			>
				{{ priceEmpty(item.PriceLowKt) }}
			</span>		
		</template>


		<template v-slot:item.PriceShop="{ item }">
			<span v-tooltip="{
				content:'Cena twojej oferty podczas ostatniego skanowania',
				delay: 600,
			}">
				{{ priceEmpty(item.PriceShop) }}
			</span>
		</template>



		<template v-slot:item.Difference="{ item }">
					<span v-tooltip="{
						content:'Różnica ceny twojej ofert z najtanszą ofertą ',
						delay: 600,
					}">{{ priceDifference(item) }}</span>
		</template>


		<template v-slot:item.PriceLowWshipping="{ item }">
			<span 
			v-on="on"
			v-tooltip="{
				content:'Najniższa cena z dostawą konkurencji',
				delay: 600,
			}"
			>
				{{ priceEmpty(item.PriceLowWshipping) }}
			</span>		
		</template>



		<template v-slot:item.PriceShopWshipping="{ item }">
			<span v-tooltip="{
				content:'Cena z dostawą twojej oferty podczas ostatniego skanowania ',
				delay: 600,
			}">
				{{ priceEmpty(item.PriceShopWshipping) }}
			</span>
		</template>



		<template v-slot:item.DifferenceWshipping="{ item }">
					<span v-tooltip="{
						content:'Różnica ceny z dostawą twojej ofert z najtanszą ofertą ',
						delay: 600,
					}">{{ priceDifferenceWshipping(item) }}</span>
		</template>


		<template v-slot:item.Position="{ item }">
					<v-card 
						v-tooltip="{
							content:'Pozycja twojej oferty / ilość ofert  ',
							delay: 600,
						}"
						v-if="posVisble(item.Position)"
						:color="getColor(item.Position)"
						dark
						label
						align="center"
						small
						class="pa-1"
						flat
					>
						<b class="no-wrap">{{ item.Position }} / {{ item.Competiton }}</b>
					</v-card>
		</template>


		<template v-slot:item.PositionKt="{ item }">
			<span v-if="posVisble(item.PositionKt)">
				<v-chip
					v-tooltip="{
						content:'Pozycja twojej oferty w rankingu kup teraz ',
						delay: 600,
					}"
					
					:color="getColorKt(item.PositionKt)"
					dark
					label
					align="center"
					small
					class="pa-1"
					flat
				>
					<b class="no-wrap" style="width: 35px!important;">{{ item.PositionKt }} </b>
				</v-chip>

				<v-btn	
					v-tooltip="{
						content:'Kalkulowanie optymalnej ceny biorąc pod uwagę tylko ceny Kup Teraz',
						delay: 600,
					}"
					
					small
					icon
					color="grey"
					@click="toggleKtStatus(item)"
					:color="getKtStatusColor(item.PositionKtTarget)"
				>
					<v-icon>mdi-check-circle-outline</v-icon>
				</v-btn>
			</span>
		</template>


		<template v-slot:item.ScrapeDate="{ item }">
			<!-- <span class="small-text">{{ new Date(item.ScrapeDate).toLocaleString('pl-PL')  }}</span> -->
			<span class="small-text">{{ processDate(item.ScrapeDate) }}</span>
		</template>

		
		<template v-slot:item.Status="{ item }">
			<span class="">

				<v-tooltip top open-delay=500>
					<template v-slot:activator="{ on }">
						<v-btn
							v-tooltip="{
								content:'Optymalna cena oferty wyliczona przez system',
								delay: 600,
							}"
							:color="getStatusColor(item.Status)"	
							small		
							icon	
							@click="toggleActive(item)"
						>
							<v-icon> {{ getStatusIcon(item.Status)}}</v-icon>
						</v-btn>
					</template>
					<span>{{getStatusToolTip(item.Status)}}</span>
				</v-tooltip>

				<v-btn	
					small
					icon
					:to=getItemUrl(item)						
				>
					<v-icon>mdi-information-outline</v-icon>
				</v-btn>


				<v-btn	
					small
					icon
					color="grey"
					@click="toggleFavorite(item)"
					:color="getFavoriteColor(item.Favorite)"
				>
					<v-icon>mdi-star</v-icon>
				</v-btn>


				<v-btn	
					v-tooltip="{
						content:item.Notes,
						delay: 100,
					}"
					:color="getNoteColor(item.Notes)"	
					small
					icon
	
					@click="showNote(item)"			
				>
					<v-icon>mdi-note</v-icon>
				</v-btn>

			</span>
		</template>

		<!-- hide column -->
		<template v-slot:item.Favorite="{ item }">
		</template>
		
	</v-data-table>

	<notes
	 :dialog = "note"
	 :title = "note_title"
	 :note = "note_text"
	 @saveNote="saveNote"
	 @closeNote="closeNote"
	/>

</div>
</template>


<script>
	import Notes from '../../components/Notes.vue'


	export default {
		components: {
			'notes' : Notes,
		},
		props: [
			'products',
			'search',
			'filter_active',
			'filter_favorites',
			'filter_high',
			'filter_low',
			'module_id',
			'loading',
		],
		methods : {
			saveData: function (item ){

				delete item.Config  // stop overwritting config

				axios.put(process.env.VUE_APP_API_URL +  '/ceneo/'+this.module_id+'/' + item.ID, {
					product: item,
				})
				.then(response => {
					if (!response.data.hasOwnProperty('updated')){
						this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
						console.log(response);
						console.log(this.error_msg);
					}

				})
				.catch(	error => { 
					this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
				});
			},

			getColor (p) {
				if (p > 5) return 'primary'
				else if (p > 1) return 'grey'
				else return 'light-green accent-4'
			},

			getColorKt (p) {
				if (p > 1) return 'orange accent-4'
				// else if (p > 1) return 'grey'
				else return 'light-green accent-4'
			},

			getStatusColor (s) {
				if (s == 'disabled') return 'primary'
				else return 'light-green accent-4'
			},

			getFavoriteColor (s) {
				if (s == '1') return 'primary'
				else return 'grey'
			},

			getKtStatusColor (s) {
				if (s == '1') return 'light-green accent-4'
				else return 'grey'
			},


			getStatusIcon(s){
				if (s == 'disabled') return 'mdi-pause-circle-outline'
				else return 'mdi-play-circle-outline'
			},

			getStatusToolTip(s){
				if (s == 'disabled') return 'Sprawdzanie Wyłączone - ceny konkurencji nie są sprawdzane, naciśnij aby aby aktywować'
				else return 'Sprawdzanie Aktywne - naciśnij aby aby wyłączyć'
			},

			toggleActive (item)
			{
				// item
				var editedIndex = this.products.indexOf(item)
				var editedItem = Object.assign({}, item)

				if (editedItem.Status == 'disabled') editedItem.Status = 'active'
				else editedItem.Status = 'disabled'

				console.log(editedItem)
				this.saveData(editedItem) //AJAX
				this.saveToTable(editedItem , editedIndex)//UPDATE TABLE DATA
			},


			toggleFavorite (item)
			{
				// item
				var editedIndex = this.products.indexOf(item)
				var editedItem = Object.assign({}, item)

				if (editedItem.Favorite == 0) editedItem.Favorite = 1
				else editedItem.Favorite = 0

				console.log(editedItem)
				this.saveData(editedItem) //AJAX
				this.saveToTable(editedItem , editedIndex)//UPDATE TABLE DATA
			},



			toggleKtStatus (item)
			{
				// item
				var editedIndex = this.products.indexOf(item)
				var editedItem = Object.assign({}, item)

				if (editedItem.PositionKtTarget == 0) editedItem.PositionKtTarget = 1
				else editedItem.PositionKtTarget = 0

				console.log(editedItem)
				this.saveData(editedItem) //AJAX
				this.saveToTable(editedItem , editedIndex)//UPDATE TABLE DATA
			},
			

			saveToTable (editedItem , editedIndex) {
				Object.assign(this.products[editedIndex], editedItem)
			},

			changeValue (v, item, property) {
				var editedIndex = this.products.indexOf(item)
				var editedItem = Object.assign({}, item)
				
				if ( property == 'PriceFrom' || property == 'PriceTo' || property == 'Shop_id'){
					// convert string from input field to float 
					editedItem[property] = parseFloat(v)
				}else {
					editedItem[property] = v
				}

				this.saveData(editedItem) //AJAX
				this.saveToTable(editedItem , editedIndex)//UPDATE TABLE DATA
			},

			getItemUrl (item) {
				return "/ceneo/" + this.module_id + "/" + item.ID
			},


			paginationVisibility(pagination) {

				if (pagination.itemsLength >1){
					this.show_pagination  = true
				}
				else {
					this.show_pagination  = false
				}
			},
			
			priceDifference(i){
				if (!isNaN(i.Difference) && i.Difference!= null && i.Difference!= '') return parseFloat(i.Difference).toFixed(2)
				if (isNaN(i.PriceShop) || i.PriceShop== null || i.PriceShop== '') return ''
				return (parseFloat(i.PriceShop) - parseFloat(i.PriceLow)).toFixed(2)
			},

			priceDifferenceWshipping(i){
				if (!isNaN(i.DifferenceWshipping) && i.DifferenceWshipping!= null && i.DifferenceWshipping!= '') return parseFloat(i.DifferenceWshipping).toFixed(2)
				if (isNaN(i.PriceShopWshipping) || i.PriceShopWshipping== null || i.PriceShopWshipping== '') return ''
				return (parseFloat(i.PriceShopWshipping) - parseFloat(i.PriceLowWshipping)).toFixed(2)
			},

			priceEmpty(p){
				if (isNaN(p) || p== null || p== ''  || p== 0) return ''
				return parseFloat(p).toFixed(2)
			},

			posVisble(p){
				if (p == 100) return false
				if (p == 0) return false
				return true
			},

			showNote (item) {
				console.log( item.Notes )
				this.note_item = item 
				this.note_item_index = this.products.indexOf(item)
				this.note =  true
				this.note_title = item.Name
				this.note_text = item.Notes

			},

			saveNote(note){
				console.log(note)
				
				if (note == '' ){
					note = ' '
				}

				// save note
				this.note_item.Notes = note
				this.saveData( this.note_item )
				this.saveToTable(this.note_item  , this.note_item_index) //UPDATE TABLE DATA

				// clear note
				this.closeNote()

			},

			closeNote(){
				this.note = false
			},

			getNoteColor (n) {
				if (n != '' && n != ' ' &&  n != null) return 'yellow darken-2'
				else return 'grey'
			},
			
			processDate(date){
				// d = String(date).replace('T', ' ')
				return String(date).replace('T', ' ').substring(0, String(date).length - 6)
			},

		},
		computed: {
			headers() {
	  			return [
					// { text: 'ceneo id', value: 'ceneo_id' },
					{ text: 'id', value: 'Ceneo_id' },
					{ text: 'id sklep', value: 'Shop_id' , width:'70px'},
					// { text: 'search', value:"SearchString" },
					{ text: 'tytuł', value:"Name" },
					{ text: 'opt.', value:"PriceOptimal", align: 'right'},
					{ text: 'min.', value:"PriceFrom" ,align: 'center'},
					{ text: 'max.', value:"PriceTo", align: 'center' },
					{ text: 'niska', value:"PriceLow" , align: 'center' , width:'50px'},
					{ text: 'niska kt.', value:"PriceLowKt" , align: 'center' , width:'50px'},
					{ text: 'cena', value:"PriceShop" , align: 'center', width:'50px'},
					{ text: 'róż.', value:"Difference" , align: 'center',  width:'50px',
						filter: value => {
							if (value > -0.01 && this.filter_low) return false
							return true
						}
					},
					// { text: 'niska zd.', value:"PriceLowWshipping" , align: 'center' },
					// { text: 'cena zd.', value:"PriceShopWshipping" , align: 'center'},
					// { text: 'róż. zd.', value:"DifferenceWshipping" , align: 'center',
					// 	filter: value => {
					// 		if (value > -0.01 && this.filter_low) return false
					// 		return true
					// 	}
					// },
					{ text: 'poz.', value:"Position" , align: 'right',
						filter: value => {
							if (value == 1 && this.filter_high) return false
							return true
						}
					},
					{ text: 'kt.', value:"PositionKt" , align: 'right', width:'80px'},
					{ text: 'data', value:"ScrapeDate", dataType: "Date" },
					{ text: 'status', value:"Status" ,           
						filter: value => {
							if (value !='active' && this.filter_active) return false
							return true
						}
					},
					{ text: 'wyb', value:"Favorite" ,    align: ' d-none',       
						filter: value => {
							if (value != 1 && this.filter_favorites) return false
							return true
						}
					}
				]
			},
		},


		data: () => ({ 
				
				sort_by :"ScrapeDate",
				sort_desc : true,
				search_string : '',
				show_pagination : true,
				
				// Notes Componet
				note : false,
				note_title : '',
				note_text : '',
				note_item : null,

		}),
	}

</script>
