<template>
<div>
	<v-data-table
			fixed-header
			dense
			item-key="ID"
			:sort-by.sync="sort_by"
			:sort-desc.sync="sort_desc"
			:headers="headers"
			:items="products"
			:items-per-page="25"
			:search="search"
			:loading=loading
			loading-text="Loading... Please wait"
			:hide-default-footer=!show_pagination
			@pagination="paginationVisibility"
		>

		<template v-slot:top="{ pagination, options, updateOptions }" v-if=show_pagination>
			<v-data-footer 
				:pagination="pagination" 
				:options="options"
				@update:options="updateOptions"
				items-per-page-text="$vuetify.dataTable.itemsPerPageText"
			/>
		</template>

		
		<template v-slot:item.Allegro_id="{ item }">
			<a :href="'https://allegro.pl/oferta/' + item.Allegro_id" target="_blank">{{item.Allegro_id}}</a>
		</template>

		<template v-slot:item.Name="{ item }" >
			<td style="max-width: 190px!important;">
				<span class="small-text">{{item.Name}}</span>
			</td>
		</template>


		<template v-slot:item.SearchString="{ item }">
			<span class="no-wrap">

				<input 
					v-tooltip="{
						content:'Słowo kluczowe wyszukiwania na Allegro',
						delay: 600,
					}"
					type="text" 
					:value=item.SearchString 
					class="cust-table-input" 
					v-on:change="event => changeValue(event.target.value , item,'SearchString')"
				>


				<v-btn 
				icon 	
				depressed	
				small	
				class="ml-1"
				:href=getSearchUrl(item) 
				target="_blank"
				>
					<v-icon>mdi-text-box-search-outline</v-icon>
				</v-btn>
			</span>		 
		</template>


		<template v-slot:item.PriceFrom="{ item }">
			<input
				v-tooltip="{
					content:'Ustaw minimalną cenę twojej oferty',
					delay: 600,
				}"
				type="text" 
				:value=priceEmpty(item.PriceFrom)
				class="cust-table-input" 
				v-on:change="event => changeValue(event.target.value , item,'PriceFrom')"
			>		         
		</template>


		<template v-slot:item.PriceTo="{ item }">
			<input
				v-tooltip="{
					content:'Ustaw maksymalną cenę twojej oferty ',
					delay: 600,
				}"
				type="text" 
				:value=priceEmpty(item.PriceTo) 
				class="cust-table-input" 
				v-on:change="event => changeValue(event.target.value , item,'PriceTo')"
			>			         
		</template>


		<template v-slot:item.PriceOptimal="{ item }">
			<input style="font-weight:bold;"
				v-tooltip="{
					content:'Wyliczona optymalna cena. Może być ręcznie zmieniona<br/> <u>UWAGA</u>: kiedy pola min. i max. są uzupełnione  <br/>cena jest wyliczana podczas skanowania.',
					delay: 600,
				}"
				type="text" 
				:value=priceEmpty(item.PriceOptimal)
				class="cust-table-input" 
				v-on:change="event => changeValue(event.target.value , item,'PriceOptimal')"
			>	

		</template>


		<template v-slot:item.PriceFrom="{ item }">
			<input
				v-tooltip="{
					content:'Ustaw minimalną cenę twojej oferty',
					delay: 600,
				}"
				type="text" 
				:value=priceEmpty(item.PriceFrom)
				class="cust-table-input" 
				v-on:change="event => changeValue(event.target.value , item,'PriceFrom')"
			>		         
		</template>

		<template v-slot:item.PriceLow="{ item }">
			<v-tooltip top open-delay=500>
				<template v-slot:activator="{ on }">
					<span v-on="on">
						{{ priceEmpty(item.PriceLow) }}
					</span>
				 </template>
				<span>Najniższa cena konkurencji</span>
			</v-tooltip>	
		</template>



		<template v-slot:item.PriceShop="{ item }">
			<v-tooltip top open-delay=500>
				<template v-slot:activator="{ on }">
					<span v-on="on">
						{{ priceEmpty(item.PriceShop) }}
					</span>
				 </template>
				<span>Cena twojej oferty podczas ostatniego skanowania</span>
			</v-tooltip>	
		</template>



		<template v-slot:item.Difference="{ item }">
			<v-tooltip top open-delay=500>
				<template v-slot:activator="{ on }">
					<span v-on="on">{{ priceDifference(item) }}</span>
				</template>
				<span>Różnica ceny twojej ofert z najtanszą ofertą </span>
			</v-tooltip>	
		</template>


		<template v-slot:item.Position="{ item }">
			<v-tooltip top open-delay=500>
				<template v-slot:activator="{ on }">
					<v-card 
						v-on="on"
						v-if="posVisble(item.Position)"
						:color="getColor(item.Position)"
						dark
						label
						align="center"
						small
						class="pa-1"
						flat
					>
						<b class="no-wrap">{{ item.Position }} / {{ item.Competiton }}</b>
					</v-card>
				</template>
				<span>Pozycja twojej oferty / ilość ofert  </span>
			</v-tooltip>	
		</template>


		<template v-slot:item.ScrapeDate="{ item }">
			<!-- <span class="small-text">{{ new Date(item.ScrapeDate).toLocaleString('pl-PL')  }}</span> -->
			<span class="small-text">{{ processDate(item.ScrapeDate) }}</span>
		</template>

		
		<template v-slot:item.Status="{ item }">
			<span class="">
				<v-btn
					v-tooltip="{
						content:'Włącz / Wyłącz monitorowanie',
						delay: 600,
					}"
					:color="getStatusColor(item.Status)"	
					small		
					icon	
					@click="toggleActive(item)"
				>
					<v-icon> {{ getStatusIcon(item.Status)}}</v-icon>
				</v-btn>
	

				<v-btn	
					small
					icon
					:to=getItemUrl(item)	
					v-tooltip="{
						content:'Detale ostatniego skanowania',
						delay: 600,
					}"					
				>
					<v-icon>mdi-information-outline</v-icon>
				</v-btn>


				<v-btn	
					small
					icon
					color="grey"
					v-tooltip="{
						content:'Dodaj do listy wyróżnionych, ofert częściej sprawdzane ',
						delay: 600,
					}"
					@click="toggleFavorite(item)"
					:color="getFavoriteColor(item.Favorite)"
				>
					<v-icon>mdi-star</v-icon>
				</v-btn>


				<v-btn	
					v-tooltip="{
						content:item.Notes,
						delay: 100,
					}"
					:color="getNoteColor(item.Notes)"	
					small
					icon
	
					@click="showNote(item)"			
				>
					<v-icon>mdi-note</v-icon>
				</v-btn>
			</span>
		</template>

		<!-- hide column -->
		<template v-slot:item.Favorite="{ item }">
		</template>
	</v-data-table>

	<notes
	 :dialog = "note"
	 :title = "note_title"
	 :note = "note_text"
	 @saveNote="saveNote"
	 @closeNote="closeNote"
	/>
	</div>
	
</template>


<script>
	import Notes from '../../components/Notes.vue'

	export default {
		components: {
			'notes' : Notes,
		},
		props: [
			'products',
			'search',
			'filter_active',
			'filter_favorites',
			'filter_high',
			'filter_low',
			'module_id',
			'loading',
		],
		methods : {
			saveData: function (item , change_price = false){

				axios.put(process.env.VUE_APP_API_URL +  '/allegro/'+this.module_id+'/' + item.ID, {
					product: item,
					change_price: change_price,
				})
				.then(response => {
					if (!response.data.hasOwnProperty('updated')){
						this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
						console.log(response);
						console.log(this.error_msg);
					}

				})
				.catch(	error => { 
					this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
				});
			},

			getColor (p) {
				if (p > 5) return 'primary'
				else if (p > 1) return 'grey'
				else return 'light-green accent-4'
			},

			getStatusColor (s) {
				if (s == 'disabled') return 'primary'
				else return 'light-green accent-4'
			},

			getFavoriteColor (s) {
				if (s == '1') return 'primary'
				else return 'grey'
			},


			getStatusIcon(s){
				if (s == 'disabled') return 'mdi-pause-circle-outline'
				else return 'mdi-play-circle-outline'
			},

			getStatusToolTip(s){
				if (s == 'disabled') return 'Sprawdzanie Wyłączone - ceny konkurencji nie są sprawdzane, naciśnij aby aby aktywować'
				else return 'Sprawdzanie Aktywne - naciśnij aby aby wyłączyć'
			},

			toggleActive (item)
			{
				// item
				var editedIndex = this.products.indexOf(item)
				var editedItem = Object.assign({}, item)

				if (editedItem.Status == 'disabled') editedItem.Status = 'active'
				else editedItem.Status = 'disabled'

				console.log(editedItem)
				this.saveData(editedItem) //AJAX
				this.saveToTable(editedItem , editedIndex)//UPDATE TABLE DATA
			},


			toggleFavorite (item)
			{
				// item
				var editedIndex = this.products.indexOf(item)
				var editedItem = Object.assign({}, item)

				if (editedItem.Favorite == 0) editedItem.Favorite = 1
				else editedItem.Favorite = 0

				console.log(editedItem)
				this.saveData(editedItem) //AJAX
				this.saveToTable(editedItem , editedIndex)//UPDATE TABLE DATA
			},
			

			saveToTable (editedItem , editedIndex) {
				Object.assign(this.products[editedIndex], editedItem)
			},

			changeValue (v, item, property) {
				var editedIndex = this.products.indexOf(item)
				var editedItem = Object.assign({}, item)
				
				if ( property == 'PriceFrom' || property == 'PriceTo'|| property == 'PriceOptimal'){
					// convert string from input field to float 
					editedItem[property] = parseFloat(v)
				}else {
					editedItem[property] = v
				}

				if (property == 'PriceOptimal' ){
					this.saveData(editedItem, true) //AJAX

				}else{
					this.saveData(editedItem) //AJAX
				}

				this.saveToTable(editedItem , editedIndex)//UPDATE TABLE DATA
			},

			getItemUrl (item) {
				return "/allegro/" + this.module_id + "/" + item.ID
			},

			getSearchUrl (item) {
				if (item.State=='new'){
					return "https://allegro.pl/listing?string="+ item.SearchString  +"&order=p&stan=nowe"
				}
				
				return "https://allegro.pl/listing?string="+ item.SearchString  +"&order=p"
			},

			paginationVisibility(pagination) {

				if (pagination.itemsLength >1){
					this.show_pagination  = true
				}
				else {
					this.show_pagination  = false
				}
			},
			
			priceDifference(i){
				if (!isNaN(i.Difference) && i.Difference!= null && i.Difference!= '') return parseFloat(i.Difference).toFixed(2)
				if (isNaN(i.PriceShop) || i.PriceShop== null || i.PriceShop== '') return ''
				return (parseFloat(i.PriceShop) - parseFloat(i.PriceLow)).toFixed(2)
			},

			priceEmpty(p){
				if (isNaN(p) || p== null || p== ''  || p== 0) return ''
				return parseFloat(p).toFixed(2)
			},

			posVisble(p){
				if (p == 100) return false
				return true
			},

			showNote (item) {
				console.log( item.Notes )
				this.note_item = item 
				this.note_item_index = this.products.indexOf(item)
				this.note =  true
				this.note_title = item.Name
				this.note_text = item.Notes

			},

			saveNote(note){
				console.log(note)
				
				if (note == '' ){
					note = ' '
				}

				// save note
				this.note_item.Notes = note
				this.saveData( this.note_item )
				this.saveToTable(this.note_item  , this.note_item_index) //UPDATE TABLE DATA

				// clear note
				this.closeNote()

			},

			closeNote(){
				this.note = false
			},

			getNoteColor (n) {
				if (n != '' && n != ' ' &&  n != null) return 'yellow darken-2'
				else return 'grey'
			},

			processDate(date){
				// d = String(date).replace('T', ' ')
				return String(date).replace('T', ' ').substring(0, String(date).length - 6)
			},

		},
		computed: {
			headers() {
	  			return [
					{ text: 'allegro id', value: 'Allegro_id' },
					{ text: 'ean', value: 'Ean' },
					{ text: 'search', value:"SearchString" },
					{ text: 'tytuł', value:"Name" },
					{ text: 'opt.', value:"PriceOptimal", align: 'right'},
					{ text: 'min.', value:"PriceFrom" ,align: 'center'},
					{ text: 'max.', value:"PriceTo", align: 'center' },
					{ text: 'niska', value:"PriceLow" , align: 'right' },
					{ text: 'cena', value:"PriceShop" , align: 'right'},
					{ text: 'róż.', value:"Difference" , align: 'right',
						filter: value => {
							if (value > -0.01 && this.filter_low) return false
							return true
						}
					},
					{ text: 'poz.', value:"Position" , align: 'right',
						filter: value => {
							if (value == 1 && this.filter_high) return false
							return true
						}
					},
					{ text: 'data', value:"ScrapeDate", dataType: "Date" },
					{ text: 'status', value:"Status" ,           
						filter: value => {
							if (value !='active' && this.filter_active) return false
							return true
						}
					},
					{ text: 'wyb', value:"Favorite" ,    align: ' d-none',       
						filter: value => {
							if (value != 1 && this.filter_favorites) return false
							return true
						}
					}
				]
			},
		},


		data: () => ({ 
				// module_id : '',
				sort_by :"ScrapeDate",
				sort_desc : true,
				search_string : '',
				show_pagination : true,
				// loading : true,

				// Notes Componet
				note : false,
				note_title : '',
				note_text : '',
				note_item : null,
		}),
	}

</script>
