<template>
	<!-- <v-container fluid mt-5 mb-5 pa-0> -->
	  
	<v-container fluid mt-5 mb-5 pa-0 >
		  <v-row no-gutters>
			  <v-col class="pa-10 pb-0">
			<v-text-field
				v-model="search_string"
				v-on:change="loadData"
				append-icon="mdi-magnify"
				label="Wyszukiwanie"
				hide-details
				clearable
				outlined
			></v-text-field>

			<v-spacer></v-spacer>
			  </v-col>
		  </v-row>
		<v-row no-gutters>
			<v-col class="pa-10">
				<v-progress-linear
					indeterminate
					color="green"
					v-if="loading"
				></v-progress-linear>

				<v-simple-table>
					<template v-slot:default>
					<thead>
						<tr>
						<th class="text-center">
							id 
						</th>
						<th class="text-center">
							data
						</th>
						<th class="text-left">
							log
						</th>
						</tr>
					</thead>
					<tbody>
						<tr
						v-for="item in logs"
						:key="item.name"
						>
						<td class="text-center">{{ item.ID }}</td>
						<td class="text-center"> {{ item.LogTime }}</td>
						<td class="text-left">{{ item.Details }}</td>

						</tr>
					</tbody>
					</template>
				</v-simple-table>
			</v-col>
			
		</v-row>
	</v-container>

</template>

<script>
export default {
	methods: {
		loadData(){
				var url
				this.loading = true

				if (this.search_string!='' && this.search_string != null){
					url = process.env.VUE_APP_API_URL + '/bclink/'+this.$route.params.module_id+ '/search?s=' + this.search_string
				}else{
					url = process.env.VUE_APP_API_URL + '/bclink/'+this.$route.params.module_id
				}

				axios.get(url)
					.then(response => { 
						this.logs = response.data
						console.log(response.data)
						this.loading = false
					 })
					.catch(	error => { 
						this.$store.commit('openDialog', {heading: 'Błąd ', message: 'Ładowanie danych nieudane.'})
						this.loading = false
					});

			},
	},

	mounted () {
		this.loadData()
	},

	data: () => ({ 
		loading: false,
		search_string : '',
		logs : [],
	})
}
</script>