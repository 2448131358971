<template>
	<v-card flat class="pa-10">
		<h1>Dodaj Dane Firmowe</h1>
		<form class="mt-10">
			<v-text-field
				ref="company_name"
				v-model="company_name"
				:rules="company_name_rules"
				clearable
				outlined
				label="Nazwa Firmy"
				required
			></v-text-field>

			<v-text-field
				ref="company_number"
				v-model="company_number"
				:rules="company_number_rules"
				clearable
				outlined
				label="NIP"
				required
			></v-text-field>

			<v-textarea
				ref="company_address"
				outlined
				v-model="company_address"
				:rules="company_address_rules"
				label="Adres Firmy"
			></v-textarea>
			<v-btn
				class="mr-4"
				@click="saveCompanyDetails"
				color="primary"
				:loading="loading"
				:disabled="invalid"
			>
				Zapisz
			</v-btn>

			<!-- <v-btn
				class="mr-4"
				@click="$emit('registrationComplete')"
				color="primary"

			>
				Omiń krok
			</v-btn> -->

		</form>
	</v-card>
</template>


<script>
	export default {

		data () {
			return {
				is_mounted : false,
				loading : false,
				company_name : '',
				company_name_rules: [
					v => !!v || 'Nazwa firmy jest wymagana',
				],
				company_number : '',
				company_number_rules : [
					v => !!v || 'NIP firmy jest wymagany',
				],
				company_address : '',
				company_address_rules: [
					v => !!v || 'Adres firmy jest wymagany',
				],
			}
		},
		mounted() {
			this.is_mounted = true
		},

		methods : {
			saveCompanyDetails: function (){
				this.loading = true

				axios.put( process.env.VUE_APP_API_URL + '/profile', {
					company_name : this.company_name,
					company_number : this.company_number,
					company_address : this.company_address,
				})
				.then(response => { 
					this.loading = false

					if (!response.data.hasOwnProperty('updated')){

						this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})

					}else{
						
						var mr = this.$store.state.module_registration
						mr.company_details = true
						this.$store.commit('setModuleRegistration', mr)

						// CONFIRMATION PAGE
						this.$emit('registrationComplete')					
					}
				})
				.catch(	error => { 
					
					this.loading = false
					this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
				});
			},
		},

		computed : {

			invalid () {
				if (this.is_mounted){
					if(
						this.$refs.company_name.valid &&
						this.$refs.company_number.valid &&
						this.$refs.company_address.valid
					){
						return false
					}
				}
				return true
			}
		}


	}
</script>
