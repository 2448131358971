<template>
    <v-overlay
      :z-index="zIndex" 
    >
		<iframe 
			width="560" 
			height="315" 
			:src="yt_url"
			title="YouTube video player" 
			frameborder="0" 
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
			allowfullscreen
		>
		</iframe>
		<br/>
      <v-btn
        light
        @click="close"
      >
        Zamknij
      </v-btn>
    </v-overlay>
</template>

<script>


export default{
	props : [
		'yt_url',
	],

	data: () => ({ 
		zIndex: 100,	

	}),

	methods : {
		close () {
			this.$emit('toggleTutorial')	
		}
	}
}
</script>
