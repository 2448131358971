var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"fixed-header":"","dense":"","item-key":"ID","sort-by":_vm.sort_by,"sort-desc":_vm.sort_desc,"headers":_vm.headers,"items":_vm.products,"items-per-page":25,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":!_vm.show_pagination},on:{"update:sortBy":function($event){_vm.sort_by=$event},"update:sort-by":function($event){_vm.sort_by=$event},"update:sortDesc":function($event){_vm.sort_desc=$event},"update:sort-desc":function($event){_vm.sort_desc=$event},"pagination":_vm.paginationVisibility},scopedSlots:_vm._u([(_vm.show_pagination)?{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}}:null,{key:"item.Allegro_id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'https://allegro.pl/oferta/' + item.Allegro_id,"target":"_blank"}},[_vm._v(_vm._s(item.Allegro_id))])]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"max-width":"190px!important"}},[_c('span',{staticClass:"small-text"},[_vm._v(_vm._s(item.Name))])])]}},{key:"item.SearchString",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
						content:'Słowo kluczowe wyszukiwania na Allegro',
						delay: 600,
					}),expression:"{\n\t\t\t\t\t\tcontent:'Słowo kluczowe wyszukiwania na Allegro',\n\t\t\t\t\t\tdelay: 600,\n\t\t\t\t\t}"}],staticClass:"cust-table-input",attrs:{"type":"text"},domProps:{"value":item.SearchString},on:{"change":function (event) { return _vm.changeValue(event.target.value , item,'SearchString'); }}}),_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","depressed":"","small":"","href":_vm.getSearchUrl(item),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-text-box-search-outline")])],1)],1)]}},{key:"item.PriceFrom",fn:function(ref){
					var item = ref.item;
return [_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
					content:'Ustaw minimalną cenę twojej oferty',
					delay: 600,
				}),expression:"{\n\t\t\t\t\tcontent:'Ustaw minimalną cenę twojej oferty',\n\t\t\t\t\tdelay: 600,\n\t\t\t\t}"}],staticClass:"cust-table-input",attrs:{"type":"text"},domProps:{"value":_vm.priceEmpty(item.PriceFrom)},on:{"change":function (event) { return _vm.changeValue(event.target.value , item,'PriceFrom'); }}})]}},{key:"item.PriceTo",fn:function(ref){
				var item = ref.item;
return [_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
					content:'Ustaw maksymalną cenę twojej oferty ',
					delay: 600,
				}),expression:"{\n\t\t\t\t\tcontent:'Ustaw maksymalną cenę twojej oferty ',\n\t\t\t\t\tdelay: 600,\n\t\t\t\t}"}],staticClass:"cust-table-input",attrs:{"type":"text"},domProps:{"value":_vm.priceEmpty(item.PriceTo)},on:{"change":function (event) { return _vm.changeValue(event.target.value , item,'PriceTo'); }}})]}},{key:"item.PriceOptimal",fn:function(ref){
				var item = ref.item;
return [_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
					content:'Wyliczona optymalna cena. Może być ręcznie zmieniona<br/> <u>UWAGA</u>: kiedy pola min. i max. są uzupełnione  <br/>cena jest wyliczana podczas skanowania.',
					delay: 600,
				}),expression:"{\n\t\t\t\t\tcontent:'Wyliczona optymalna cena. Może być ręcznie zmieniona<br/> <u>UWAGA</u>: kiedy pola min. i max. są uzupełnione  <br/>cena jest wyliczana podczas skanowania.',\n\t\t\t\t\tdelay: 600,\n\t\t\t\t}"}],staticClass:"cust-table-input",staticStyle:{"font-weight":"bold"},attrs:{"type":"text"},domProps:{"value":_vm.priceEmpty(item.PriceOptimal)},on:{"change":function (event) { return _vm.changeValue(event.target.value , item,'PriceOptimal'); }}})]}},{key:"item.PriceLow",fn:function(ref){
				var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.priceEmpty(item.PriceLow))+" ")])]}}],null,true)},[_c('span',[_vm._v("Najniższa cena konkurencji")])])]}},{key:"item.PriceShop",fn:function(ref){
				var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.priceEmpty(item.PriceShop))+" ")])]}}],null,true)},[_c('span',[_vm._v("Cena twojej oferty podczas ostatniego skanowania")])])]}},{key:"item.Difference",fn:function(ref){
				var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.priceDifference(item)))])]}}],null,true)},[_c('span',[_vm._v("Różnica ceny twojej ofert z najtanszą ofertą ")])])]}},{key:"item.Position",fn:function(ref){
				var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [(_vm.posVisble(item.Position))?_c('v-card',_vm._g({staticClass:"pa-1",attrs:{"color":_vm.getColor(item.Position),"dark":"","label":"","align":"center","small":"","flat":""}},on),[_c('b',{staticClass:"no-wrap"},[_vm._v(_vm._s(item.Position)+" / "+_vm._s(item.Competiton))])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Pozycja twojej oferty / ilość ofert ")])])]}},{key:"item.ScrapeDate",fn:function(ref){
				var item = ref.item;
return [_c('span',{staticClass:"small-text"},[_vm._v(_vm._s(_vm.processDate(item.ScrapeDate)))])]}},{key:"item.Status",fn:function(ref){
				var item = ref.item;
return [_c('span',{},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
						content:'Włącz / Wyłącz monitorowanie',
						delay: 600,
					}),expression:"{\n\t\t\t\t\t\tcontent:'Włącz / Wyłącz monitorowanie',\n\t\t\t\t\t\tdelay: 600,\n\t\t\t\t\t}"}],attrs:{"color":_vm.getStatusColor(item.Status),"small":"","icon":""},on:{"click":function($event){return _vm.toggleActive(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.getStatusIcon(item.Status)))])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
						content:'Detale ostatniego skanowania',
						delay: 600,
					}),expression:"{\n\t\t\t\t\t\tcontent:'Detale ostatniego skanowania',\n\t\t\t\t\t\tdelay: 600,\n\t\t\t\t\t}"}],attrs:{"small":"","icon":"","to":_vm.getItemUrl(item)}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
						content:'Dodaj do listy wyróżnionych, ofert częściej sprawdzane ',
						delay: 600,
					}),expression:"{\n\t\t\t\t\t\tcontent:'Dodaj do listy wyróżnionych, ofert częściej sprawdzane ',\n\t\t\t\t\t\tdelay: 600,\n\t\t\t\t\t}"}],attrs:{"small":"","icon":"","color":"grey","color":_vm.getFavoriteColor(item.Favorite)},on:{"click":function($event){return _vm.toggleFavorite(item)}}},[_c('v-icon',[_vm._v("mdi-star")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
						content:item.Notes,
						delay: 100,
					}),expression:"{\n\t\t\t\t\t\tcontent:item.Notes,\n\t\t\t\t\t\tdelay: 100,\n\t\t\t\t\t}"}],attrs:{"color":_vm.getNoteColor(item.Notes),"small":"","icon":""},on:{"click":function($event){return _vm.showNote(item)}}},[_c('v-icon',[_vm._v("mdi-note")])],1)],1)]}},{key:"item.Favorite",fn:function(ref){
					var item = ref.item;
return undefined}}],null,true)}),_c('notes',{attrs:{"dialog":_vm.note,"title":_vm.note_title,"note":_vm.note_text},on:{"saveNote":_vm.saveNote,"closeNote":_vm.closeNote}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }