<template>
	<v-data-table
			fixed-header
			dense
			item-key="ID"
			:sort-by.sync="sort_by"
			:sort-desc.sync="sort_desc"
			:headers="headers"
			:items="prices"
			:items-per-page="100"
			:item-class=rowClasses
			:search="search"
			:hide-default-footer=!show_pagination
	
		>


		<template v-slot:top="{ pagination, options, updateOptions }">
			<v-data-footer 
				:pagination="pagination" 
				:options="options"
				@update:options="updateOptions"
				items-per-page-text="$vuetify.dataTable.itemsPerPageText"
			/>
		</template>


		<template v-slot:item.name="{ item }">
			<span class="small-text">{{item.name}}</span>
		</template>


		<template v-slot:item.price="{ item }">
				{{ parseFloat(item.price).toFixed(2) }}
		</template>

		<template v-slot:item.seller="{ item }">
			<span class="no-wrap">
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn
							v-on="on"
							:color="getBLSellerColor(item)"	
							small		
							icon	
							@click="toggleBLSeller(item)"
						>					
							<v-icon>mdi-playlist-remove</v-icon>
						</v-btn>
					</template>
					<span>Dodaj sprzedawcę do czarnej listy, wszystkie oferty sprzedawcy będą ignorowane.</span>
				</v-tooltip>

				{{item.seller}} 
			</span>
		</template>

	
	</v-data-table>
</template>


<script>
	export default {
		props: [
			'prices',
			'offer_id',
			'blacklist_offers',
			'blacklist_sellers',
			'config',
		],
		methods : {
			saveBlacklistOffer: function (seller, status , type){
				axios.put( process.env.VUE_APP_API_URL + '/ceneo/'+this.$route.params.module_id +'/' +type  , {
					seller : seller,
					status : status,
				})
				.then(function (response) {
					if (!response.data.hasOwnProperty('updated')){
						this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
					}

				})
				.catch(	error => { 
					this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})	
				});
			},

			rowClasses(item) {
				if (this.config != null){
					if (this.config.shop_id == item.seller) {
						return "light-green accent-2"; //can also return multiple classes e.g ["orange","disabled"]
					// }else if( this.blacklist_offers.indexOf(parseInt(item.offer_id)) > -1 ) {
					// 	return "red lighten-4";
					}else if( this.blacklist_sellers.indexOf(item.seller) > -1 ) {
						return "red lighten-4";
					}
					
				}
				
			},

			// getBLOfferColor (item){
			// 	if( this.blacklist_offers.indexOf(parseInt(item.offer_id)) > -1 ) {
			// 		return 'red'
			// 	}
			// 	return 'grey'
			// },

			getBLSellerColor (item){
				if( this.blacklist_sellers.indexOf(item.seller) > -1 ) {
					return 'red'
				}
				return 'grey'
			},

			toggleBLOffer (item ){
				var i = this.blacklist_offers.indexOf(parseInt(item.offer_id))
				if( i > -1 ) {
					var status = 'disabled'
					this.blacklist_offers.splice(i, 1); //REMOVE FROM BLACKLIST

				} else {
					var status = 'active'
					this.blacklist_offers.push(parseInt(item.offer_id)); //ADD TO BLACKLIST
				}
				this.saveBlacklistOffer(item.offer_id, status , 'blacklistoffer') //AJAX
			},

			toggleBLSeller (item){
				console.log(this.blacklist_sellers)
				var i = this.blacklist_sellers.indexOf(item.seller)
				if( i > -1 ) {
					var status = 'disabled'
					this.blacklist_sellers.splice(i, 1); //REMOVE FROM BLACKLIST

				} else {
					var status = 'active'
					this.blacklist_sellers.push(item.seller); //ADD TO BLACKLIST
				}
				this.saveBlacklistOffer(item.seller, status , 'blacklistseller') //AJAX
			}
		},

		mounted () {
			this.module_id = this.$route.params.module_id
			this.product_id = this.$route.params.product_id
		},

		data: () => ({ 
				module_id : '',
				module_id : '',
				sort_by :"prices",
				sort_desc : true,
				search_string : '',
				show_pagination : true,
				search : '',
				headers: [
					
					{ text: 'tytul', value:"title" },
					{ text: 'cena', value:"price" , align: 'right'},
					{ text: 'cena zd.', value:"pricewshipping" , align: 'right'},
					{ text: 'sprzedawca', value:"seller" },  // uncomment if sellers blackist becomes again possible
				],
		}),
	}

</script>
