<template>
  <v-container fluid ma-0 pa-0>
	  <template v-for="item in notifications">
		<v-alert  text prominent :type="item.Type" v-if="item.Visible" class="rounded-0">
			{{item.Message}} {{ new Date(item.CreatedAt).toLocaleString() }}
			<v-btn icon small light @click="closeNotif(item)"   class="float-right"  v-if="item.CanClose">
				<v-icon >mdi-close-circle</v-icon>
			</v-btn> 
		</v-alert>
	</template>
  </v-container>
</template>


<script>
  export default {
	props: [
		'notifications',
		'module_type',
	],
	methods : {
		closeNotif ( item ){
			
			item.Visible = false
			axios.put(process.env.VUE_APP_API_URL + '/' + this.module_type + '/' + this.$route.params.module_id +'/notifs/' + item.ID, {
				notif: item,
			})
			.then(function (response) {
				if (!response.data.hasOwnProperty('updated')){
					this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
					console.log(response);
					console.log(this.error_msg);
				}
				item.Visible = false
			})
			.catch(	error => { 
				this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
			});

		}
	},
  }
</script>