<template>

	<v-card flat>
		<v-tabs grow v-model="tab" height="80">
			<v-tab key="1"><h3>1. Nowa Usługa</h3></v-tab>
			<v-tab key="2"><h3>2. Autoryzacja API</h3></v-tab>
			<!-- <v-tab key="3"><h3>3. Metody Płatności</h3></v-tab> -->
			<v-tab key="4"><h3>3. Dane Firmowe</h3></v-tab>
			<v-tab key="5"><h3></h3></v-tab>
		</v-tabs>


  <v-row justify="center">
    <v-col
      cols="12"
      sm="8"
    >
			<v-tabs-items v-model="tab" class="ma-10" justify="center">
				<v-tab-item key="1">
					<v-card flat class="pa-10" >
						<h1>Dodaj Usługę</h1>
						<form class="mt-10" v-if="!$store.state.module_registration.created">
							<v-text-field
								v-model="module_name"
								clearable
								outlined
								:rules="module_name_rules"
								:counter="15"
								label="Nazwij Usługę"
								required

							></v-text-field>
							<v-select
								outlined
								v-model="module_type"
								:items="types"
								label="Usługa"
								required
								@change="$v.select.$touch()"
								@blur="$v.select.$touch()"
							></v-select>

							<v-btn
								class="mr-4"
								@click="createModule"
								color="primary"
							>
								Dodaj Usługę
							</v-btn>
							<!-- <v-card class="mt-10 rounded-0 pa-10">
								Usługa Bot Cenowy Allegro monitoruje ceny konkurencji i dostosowuje cenę twoich ofert. <br/> 
								<a href="">Dowiedz się więcej</a>
							</v-card> --> 
						</form>

						<v-alert text prominent type="success"  class="rounded-0 mt-10" v-if="$store.state.module_registration.created">
							Usługa stworzona - przejdź do następnego kroku
							<v-btn
								small 
								@click="tab=1"
								light
								class="mr-4 float-right"
							>
								OK
							</v-btn>
						</v-alert>
					</v-card>
				</v-tab-item>

				<v-tab-item		key="2">
					<v-card flat class="pa-10">
						<h1>Autoryzacja Allegro API</h1>
						<v-alert text prominent type="success"  class="rounded-0 mt-10" v-if="$store.state.module_registration.authrized">
							Udana autoryzacja API Allegro - przejdź do następnego kroku
							<v-btn
								small 
								@click="tab=2"
								light
								class="mr-4 float-right"
							>
								OK
							</v-btn>
						</v-alert>

						<v-card flat class="mt-10" v-if="$store.state.module_registration.created">
							<v-card class="mt-10 rounded-0 pa-10">
								Usługa Bot Cenowy Allegro wymaga dostępu do API Allegro. 
							</v-card>
							<v-btn
							
								class="mt-10"
								color="primary"
								:href="api_url + 'allegro/'+module_id+'/oauth'" target="_blank" 
							>
								Autoryzuj
							</v-btn>

						</v-card >

						<v-alert text prominent type="warning"  class="rounded-0 mt-10" v-if="!$store.state.module_registration.created">
							Najpierw należy stworzyć nową usługę 
							<v-btn
								small 
								@click="tab=0"
								light
								class="mr-4 float-right"
							>
								OK
							</v-btn>

						</v-alert>


						
					</v-card>
				</v-tab-item>

				<!-- <v-tab-item	key="3">
					<v-card flat class="pa-10">
						<h1>Metoda Płatności</h1>
						<v-card flat class="mt-10" v-if="$store.state.module_registration.authrized">
							<stripe-card :tab="tab" @setTab="setTab"/>
							
							<v-card class="mt-10 rounded-0 pa-10">
								<b>Okres probny:</b> Mozna rezygonwac z usługi przed upływem pierwszych dwóch tygodni - wtedy płatność nie będzie pobrana.
								<br/><br/>
								Płatność jest pobierana automatycznie co dwa tygodnie. Koszt usługi jest zależny od ilości aktywnie monitorowanych produktów: 
								<br/><br/>
								<v-btn
								small 
								to="/prices"
							
								>
									Cennik
								</v-btn>
								
								<br/><br/>
								
							</v-card>
							
						</v-card >

						<v-alert text prominent type="warning"  class="rounded-0 mt-10" v-if="!$store.state.module_registration.authrized">
							Należy stworzyć nową usługę i zautoryzowac API Allegro
							<v-btn
								small 
								@click="tab=1"
								light
								class="mr-4 float-right"
							>
								OK
							</v-btn>

						</v-alert>
					</v-card>
				</v-tab-item> -->


				<v-tab-item	key="4">
					<company-details @registrationComplete="registrationComplete"/>
				</v-tab-item>


				<v-tab-item	key="5">
					<v-card flat class="pa-10">
						<h1>Rejestracja Zakonczona</h1>
						<form class="mt-10">
							<v-btn
								class="mr-4"
								@click="finishAndReset"
								color="primary"
							>
								OK
							</v-btn>
						</form>
					</v-card>
				</v-tab-item>

			</v-tabs-items>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import StripeCard from '../components/StripeCard.vue'
	import CompanyDetails from '../components/CompanyDetails.vue'

	export default {
		components :{
			'stripe-card': StripeCard,
			'company-details': CompanyDetails,
		},
		mounted () {

			if (this.module_id_comp != null ){
				var mr = this.$store.state.module_registration

				if (this.getModuleStatus() == 'active' ){
					this.tab = 2
					mr.created = true
					mr.authrized = true
					mr.payment = true
				}

				// if (this.getModuleStatus() == 'payment' ){
				// 	this.tab = 2
				// 	mr.created = true
				// 	mr.authrized = true
				// }

				if ( this.getModuleStatus() == 'registering' ) {
					this.tab = 1
					mr.created = true
				}

				this.$store.commit('setModuleRegistration', mr)
				console.log(this.$store.state.module_registration)
			}

		},
		data () {
			return {
				api_url : process.env.VUE_APP_API_URL,
				tab: null,
				module_type: 'Bot Cenowy - Allegro',
				types: [
					'Bot Cenowy - Allegro',
					'Bot Cenowy - Ceneo',
					'Bot Cenowy - Inne Portale',
					'BC Link - Synchronizacja Cen i Stanow',
				],
				module_id : null,
				module_name : '',
				module_name_rules: [
					v => !!v || 'Nazwa jest wymagana',
					v => v.length <= 15 || 'Nazwa musi mieć mniej niż 15 liter',
				],
			}
		},	
		methods : {

			// CREATE NEW MODULE
			createModule: function (){
				var m
				switch(this.module_type) {
					case 'Bot Cenowy - Allegro':
						m = 'allegro'
					break;

					case 'Bot Cenowy - Ceneo':
						m = 'ceneo'
					break;

					case 'Bot Cenowy - Inne Portale':
						m = 'general'
					break;

					case 'BC Link - Synchronizacja Cen i Stanow':
						m = 'bclink'
					break;
	
				}

				axios.post( process.env.VUE_APP_API_URL + '/newmodule', {
					module_type: m,
					module_name: this.module_name
				})
				.then(response => { 
					if (!response.data.hasOwnProperty('created')){
						this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
					}else{
						//move to step 2
						this.module_id = response.data.created
						this.tab = 1

						if (this.module_type!='Bot Cenowy - Allegro'){
							this.tab=2
						}

						var mr = this.$store.state.module_registration
						mr.created = true
						this.$store.commit('setModuleRegistration', mr)	
						this.$router.push({ path: '/newmodule/'+ this.module_id })	

					}
				})
				.catch(	error => { 
					this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
				});
			},


			getModuleStatus: function (){
				for (var i = 0; i < this.$store.state.modules.length; i++) {
					if (this.$store.state.modules[i].module_id == this.$route.params.module_id ){
							return this.$store.state.modules[i].status
					}
				}
				return ''
			},

			registrationComplete: function () {

				console.log(this.$store.state.module_registration)

				//if status active move to completed page:
				if (!this.$store.state.module_registration.authrized){
					this.tab = 0
				} else if  (!this.$store.state.module_registration.authrized ){
					this.tab = 1
				// } else if (!this.$store.state.module_registration.payment ){
				// 	this.tab = 2
					if (this.module_type!='Bot Cenowy - Allegro'){
						this.tab=2
					}
				} else if (!this.$store.state.module_registration.company_details ){
					this.tab = 2
					// skip auth for non allegro 

				} else {

					this.tab = 3

					//reload module data
					this.$store.dispatch('getProfile')
				}

				console.log(this.tab)
			},

			setTab: function (id) {
				this.tab = id
			},

			finishAndReset: function(){

				//Reset module
				var mr = this.$store.state.module_registration
				mr.created = false
				mr.authrized = false
				mr.payment = false
				mr.company_details = false
				this.$store.commit('setModuleRegistration', mr)

				this.module_id = null
				this.$router.push({ path: '/allegro/'+ this.$route.params.module_id })
			
			},

		},
		computed: {

			rdy_2_authorize : function(){
				if (this.module_id_comp == null ){
					return false
				}

				return true
			},
			
			module_id_comp : function(){
				if (this.$route.params.module_id != null) {
					this.module_id =  this.$route.params.module_id
					return this.$route.params.module_id
				}

				return this.module_id
			},

			api_authorized : function () {
				if (this.getModuleStatus() == 'payment' || this.getModuleStatus() == 'active'){
					return true
				}
				
				return false
			}



		},
	}
</script>