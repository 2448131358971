<template>
	<v-card flat class="ma-15 pa-10"  justify="center" align="center">
		<h1>Cennik Usługi Allegro Bot Cenowy</h1>
		<v-simple-table light class="ma-10" >
			<template v-slot:default>
				<thead >
					<tr>
					<th class="text-center">
						Ilosc Monitorowanych Produktow <br/> (aktywnych w systemie)
					</th>
					<th class="text-center">
						Cena PLN / Miesiąc
					</th>
					</tr>
				</thead>
				<tbody>
					<tr class="text-center"
					v-for="item in prices_allegro"
					:key="item.products"
					>
					<td>{{ item.products }}</td>
					<td>{{ item.price }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
		<v-btn
			class="mr-4"
			to="/newmodule"
			color="primary"
			>
			Dodaj Usługę
		</v-btn>
	  </v-card>
</template>

<script>
	export default {
		mounted () {

		},

		data () {
			return {
				prices_allegro : [
					// {
					// 	products: 200,
					// 	price: '199,00 PLN',
					// },
					{			
						products: 1000,
						price: '499,00 PLN',
					},
					{			
						products: 3000,
						price: '999,00 PLN',
					},
					{			
						products: '3000+',
						price: 'indywidualna',
					},
				]
			}
		}
	}
</script>