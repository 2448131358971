<template>
	<v-container fluid  mb-5 pa-2>
		<h2 >{{product_title}}</h2>
		<span class="small-text">ceneo/{{this.$route.params.module_id}}/{{this.$route.params.product_id}}</span>

		<v-container mt-1></v-container>

		<products-table 
			:products=products 
			:module_id=module_id
		/>

		<v-container mt-10></v-container>

		<h3>Ostatni Skan: {{ new Date(scrape_date).toLocaleString() }}</h3>
		<prices-table 
			:prices=prices
			:offer_id=offer_id
			:blacklist_offers=blacklist_offers
			:blacklist_sellers=blacklist_sellers
			:config=config
		/>
	</v-container>
</template>

<script>
	import ProductsTable from '../components/ceneo/ProductsTable.vue'
	import PricesTable from '../components/ceneo/PricesTable.vue'
	
	export default {
		components :{
			'products-table': ProductsTable,
			'prices-table' : PricesTable,
		},
		methods : {
			loadData: function() {
				this.products = []
				axios.get( process.env.VUE_APP_API_URL + '/ceneo/'+this.$route.params.module_id +'/' + this.$route.params.product_id,)
					.then(response => {
						this.products = [response.data.product]

						this.product_title = response.data.product.Name
						this.scrape_date = response.data.product.ScrapeDate
						this.offer_id = response.data.product.Allegro_id
						this.config = response.data.product.Config

						// handle null responses instead of []
						if (response.data.product.Prices != null) this.prices = response.data.product.Prices
							else this.prices = []

						if (response.data.blacklist_offers != null) this.blacklist_offers = response.data.blacklist_offers
							else this.blacklist_offers = []
						
						if (response.data.blacklist_sellers != null) this.blacklist_sellers = response.data.blacklist_sellers
							else this.blacklist_sellers = []

					})
					.catch(	error => { 
						this.$store.commit('openDialog', {heading: 'Błąd ', message: 'Ładowanie danych nieudane.'})
					});
				},
		},		
		
		mounted () {
			this.module_id = this.$route.params.module_id
			this.product_id = this.$route.params.product_id
			this.loadData()
		},

		data: () => ({ 
			product_title : 'No Product Title',
			scrape_date : '',
			module_id : '',
			product_id : '',
			offer_id : '',
			config : {},
			save_failed : false,
			products :  [],
			prices :  [],
			blacklist_offers : [],
			blacklist_sellers : [],
		}),

		watch: { $route(to, from) { 
			if(to !== from){ 
				this.loadData()
			} } 
		},
	}
</script>

<style>
	h2 {
		color: "#1d353ee3" !important;
	}
</style>