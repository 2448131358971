<template>
	<v-card>
		<v-card class="mt-10 rounded-0 pa-10" v-if="!card_added">
			<h1>Dodaj Kartę</h1>
			<form class="mt-10">
			<v-text-field
				v-model="cardholder_name"
				clearable
				outlined
				label="Imię na karcie"
				required
			></v-text-field>

				<div class="card-form-outline">
					<div id="card-element"></div>
				</div>

				<v-img
					class="float-right"
					width="180"
					src="@/assets/stripe_badge.svg"
				></v-img>

				<v-btn
					class="mr-4"
					@click="createSetupIntentAndSave"
					color="primary"
					:loading="loading"
				>
					Dodaj Kartę
				</v-btn>

			</form>
		</v-card>
		<v-alert text prominent type="success"  class="rounded-0 mt-10"  v-if="card_added">
			Karta Dodana
			<v-btn
				small 
				@click="stepCompleted"
				light
				class="mr-4 float-right"
			>
				OK
			</v-btn>

		</v-alert>
	</v-card>
</template>

<script>

	import VueScript2 from 'vue-script2'

	export default {
		props: [
			'tab',
		],
		mounted () {	

			VueScript2.load('https://js.stripe.com/v3/').then(result => {
				this.stripe = Stripe(
					// 'pk_test_51Is5EuCI1RxfhodbLU3jJcOPYaDB99al05Gp2rP3D6AF158Q0uRdNxtzslo0TbjKHJPo8tViq5VgsbE7KoYRVQtH00ltjcjd9K',
					process.env.VUE_APP_STRIPE_KEY,
					{
						locale: 'pl'
					}
				)

				var elements = this.stripe.elements();
				this.card_element = elements.create('card');
				this.card_element.mount('#card-element');
				this.loading = false;
			})
		},
		computed : {

		},
		methods: {

			createSetupIntentAndSave(){
				this.loading = true
			
				axios.get(process.env.VUE_APP_API_URL + 'stripe/setupintent')
				.then(response => {
					this.client_secret = response.data
					this.saveCard()
				})
				.catch( error => {
					console.log(error);
					this.$store.commit('openDialog', {heading: 'Błąd', message: 'Uwaga: wystąpił błąd.'})
				});
			},

			saveCard () {
				this.stripe.confirmCardSetup(
					this.client_secret,
					{
						payment_method: {
						card: this.card_element,
							billing_details: {
								name: this.cardholder_name,
							},
						},
					}
				).then( result  =>  {
					
					if (result.error) {
						// Display error.message in your UI.
						this.$store.commit('openDialog', {heading: 'Błąd Zapisu',  message: 'Upewnij się czy dane karty i imię na karcie są poprawne.  Wymagany jest: numer karty, data ważności, numer CCV i kod pocztowy.' })
						console.log( result.error.message)
						this.loading = false

					} else {
						// The setup has succeeded. Display a success message
						// this.$store.commit('openDialog', {heading: 'Karta Dodana', message: '' })

						//send data to server to activate
						this.confirmCardAdded()

						var mr = this.$store.state.module_registration
						mr.payment = true
						this.$store.commit('setModuleRegistration', mr)

						//change tab
						this.card_added = true
						this.stepCompleted() 
					}
				})
			},

			confirmCardAdded () {

				axios.put( process.env.VUE_APP_API_URL + 'stripe/cardadded/' + this.$route.params.module_id, {
					module_id : this.$route.params.module_id,
				})
				.catch(	error => { 
					
					this.loading = false
					this.$store.commit('openDialog', {heading: 'Błąd Zapisu', message: 'Zmiana nie została zapisana.'})
				});

			},

			stepCompleted() {
				this.$emit('setTab', 3)
			}
		},

		data () {
			return {
				// stripe_loaded : false,
				card_added : false,
				stripe : null,
				card_element : null,
				client_secret : null,
				cardholder_name : '',
				loading : true,
			}
		},
		
	}
</script>

<style >
	.card-form-outline {
		border: 1px solid #9e9e9e;
		padding: 17px;
		border-radius: 5px;
		margin-bottom: 31px;
	}

</style>
